import React, { Component } from "react"
import { Link } from "gatsby"

class NavBarItem extends Component {
    render() {
        return (
            <div className="NavBarItem">
                <Link to={this.props.to} activeClassName="active">
                    <div className="NavBarItem-icon">
                        <div className="NavBarItem-icon-notactive">
                            {this.props.notActiveSVG}
                        </div>
                        <div className="NavBarItem-icon-active">
                            {this.props.isActiveSVG}
                        </div>
                    </div>
                    <span>{this.props.label}</span>
                </Link>
            </div>
        )
    }
}

export default NavBarItem